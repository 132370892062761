import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const alajeunessezairoise = () => (
  <Layout>
    <SEO title="Bananes Citronnées - À la jeunesse zairoïse" />
    <h3 className='underline-title'>À la jeunesse zairoïse</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ne soyez pas comme nous d'hier:<br />
        Hommes effacés citoyens sans patriotisme<br />
        Peuple sans idéal zèbre jamais fier<br />
        Victimes de jeux divers et du dogmatisme<br />
      </p>
      <p>
        Ce qu'il vous faut jeunesse montante<br />
        C'est lutte acharnée contre l'analphabétisme<br />
        Mère d'incompréhension abêtissante<br />
        Agent principal de l'obscurantisme<br />
      </p>
      <p>
        Gardez acquis les bons principes<br />
        Pain et véhicule des hommes de trempe<br />
        Lait connu depuis le fragile berceau<br />
        Ne jetez jamais ce précieux cadeau<br />
      </p>
      <p>
        Pour construire et valoriser un pays<br />
        C'est comme au désert creuser un puits<br />
        D'où jaillit et coule l'eau désaltérante<br />
        Source de fécondité et grande fertilisante<br />
      </p>
      <p>
        Beaucoup d'obstacles il est certain<br />
        Mais aucun effort ne reste vain<br />
        Quelle oeuvre obtient-on sans peine?<br />
        Il n'y a pas de rose sans épines<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default alajeunessezairoise
